/**
 * Direttiva che costruisce la barra di ricerca con i filtri
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface ISearchBarDirectiveScope extends ng.IScope {
	isBackBtnDisabled: boolean;
	searchId: number;
	isRemoveAllFiltersBtnDisabled: boolean;
	searchBarFilters: any;
	searchBarManager: any;
	filtersToRetrieve: string;
}
angular.module('app').directive("searchBar", ($translate, SearchBarManager, $rootScope) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			saveNewSearch: '=',
			historyBack: '=',
			searchId: '=',
			hasTabsAtLeastOneFilter: '=',
			removeFilters: '=',
			inputPlaceholder: '=',
			unsetLibraryFilter: '=',
			setLibraryFilter: '=',
			changeLetters: '=',
			isFirstTabSelected: '=',
			filterParams: '=',
			subTabLibraryFiltersChanged: '=',
			filtersToRetrieve: '@'
		},
		link: link,
		templateUrl: 'app/shared/searchBar/searchBar.html'
	};
	function link($scope: ISearchBarDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		$scope.searchBarManager = SearchBarManager;

		// Recupero i filtri disponibili per la barra di ricerca
		$scope.searchBarFilters = SearchBarManager.getAllFilters($scope.filtersToRetrieve);

		// Visto che quando arrivo in Library dalla GLP la searchBar si inizializza prima del recupero dei dati, devo alzare un evento per fare il refresh dei dati
		$rootScope.$on('refreshSearchBarFilters', (event: any) => {
			$scope.searchBarFilters = null;
			$scope.searchBarFilters = SearchBarManager.getAllFilters($scope.filtersToRetrieve);
		});

		// Se non ho ancora effettuato una ricerca, disabilito il pulsante per tornare indietro a fianco l'input di ricerca
		if ($scope.searchId <= 1) {
			$scope.isBackBtnDisabled = true;
		}

		// Se non ho nessun filtro, disabilito il pulsante per rimuovere tutti i filtri (tranne le categorie)
		if (!SearchBarManager.countSelectedFilters(true)) {
			$scope.isRemoveAllFiltersBtnDisabled = true;
		}
	}
});